<template>
  <div class="flex flex-col gap-1">
    <div v-if="isNew" class="bg-[#f5f5f5] text-black" :class="style">
      <span class="capitalize">{{ trans('novelty') }}</span>
    </div>
  
    <div v-if="parseInt(discountPercentRef) > 0" class="bg-cx3-primary text-white" :class="style">
      <span class="capitalize">{{ trans('sale') }}</span>
      <span class=""> {{ discountPercentRef }}% </span>
    </div>
  
    <template v-if="tags.length">
      <div v-for="tag in tags" :key="tag.id" class="bg-cx1-primary text-white" :class="style"
        :style="(tag?.color) ? 'background-color:' + tag.color : ''">
        <span class=""> {{ tag.name }} </span>
      </div>
    </template>
  </div>
  </template>
  
  <script setup>
  import { shallowRef, computed, inject } from "vue";
  
  const props = defineProps({
    product: {
      type: Object,
      required: true,
    },
    container: {
      type: Boolean,
      required: false,
      default: false,
    },
    list: {
      type: Boolean,
      required: false,
      default: false,
    },
  });
  
  const style = shallowRef('w-fit flex items-center justify-center text-center font-bold gap-1');
  
  if (props.container) {
    style.value = `${style.value} h-6 lg:h-8 px-4 lg:px-2 text-sm rounded-r-lg`;
  } else if (props.list) {
    style.value = `${style.value}`;
  } else {
    style.value = `${style.value} text-xl px-12 py-2 rounded-r-xl`;
  }
  
  // Inject the reactive discountPercent from the parent component
  const { discountPercent } = inject('Product', shallowRef(null));
  
  // Use computed to dynamically update the discount percentage
  const discountPercentRef = computed(() => {
    // Use discountPercent from the selectedProduct if it exists
    return discountPercent.value ?? props.product.display_price.discount_percent;
  });
  
  // Calculate tags and other properties as before
  const tags = computed(() => {
    let tags = props.product?.tags ?? [];
    if (tags.length) {
      tags.forEach(t => {
        if (!t?.color && t?.pictureUrl && t?.pictureUrl?.indexOf('#') > -1) {
          t.color = t.pictureUrl.split('/').pop().trim();
        }
      });
    }
    return tags;
  });
  
  const isNew = computed(() => {
    if (!props.product?.created_at) return null;
    let date1 = new Date(props.product.created_at);
    let date2 = Date.now();
    let diffTime = Math.abs(date2 - date1);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 30 ? true : false;
  });
  </script> 
   